.Intro-title {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  width: 100%;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 40px;

  .colored {
    color: #3ebd73;
  }
}

.container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
