.tabWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 6px;
  .tabTitle {
    font-size: 20px;
    font-weight: 500;
    color: #b4b4b4;
    margin: 0px 48px;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
  .tabTitleClicked {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin: 0px 48px;
    border-bottom: 2px solid black;
    cursor: pointer;
  }
}
.img {
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.tabContent {
  font-size: 18px;
  font-weight: 400;
  padding-top: 32px;
  display: flex;
  justify-content: center;
}
