// @import '~antd/dist/antd.min.css';
@import '../antd-custom/custom-antd.css';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  word-break: keep-all;
}

.content-wrapper {
  & + & {
    margin-top: 16px;
  }
}
