.title {
  width: 100%;
  height: 100%;
  background-color: #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  font-weight: 700;
}

.content {
  background-color: #fff;
  color: #666666;
  text-align: center;
  font-size: 20px;
  line-height: 1.7;
  font-weight: 400;
  padding: 60px 0px;
}
