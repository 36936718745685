.button {
  width: 88px;
  height: 36px;
  background-color: #3ebd73;
  border: 1px solid #3ebd73;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0px 0px 16px;
  cursor: pointer;

  &.noColor {
    background-color: transparent;
    color: #3ebd73;
    &:hover {
      transition: all 0.2s linear;
      background-color: rgba(62, 189, 115, 0.12157);
    }
  }

  &.reversed {
    color: #3ebd73;
    background-color: #fff;
    border: 1px solid #fff;
  }

  &.large {
    padding: 15px 50px;
    width: max-content;
    height: min-content;
    font-size: 24px;
    font-weight: 600;
  }

  &.medium {
    padding: 10px 20px;
    width: max-content;
    height: max-content;
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }

  &.small {
    padding: 3px 6px;
    width: max-content;
    height: max-content;
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
  }
}

.topTab {
  width: 80px;
  height: 40px;
  background-color: transparent;
  font-weight: 600;
  border: none;
}

.topTabMobile {
  font-weight: 400;
  background-color: transparent;
  border: none;
  padding: 20px;

  &:active {
    background-color: rgb(237, 237, 237);
    transition: all 0.2 linear;
  }
}
