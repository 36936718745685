.bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  background-color: #fafafa;
}
.title {
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 60px;
  .colored {
    color: #3ebd73;
  }
}

.merit {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1250px) {
    width: 1000px;
  }
  .container {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000de;
    .meritTitle {
      font-size: 24px;
      font-weight: 600;
      margin: 15px 0px 10px 0px;
    }
    .meritContent {
      text-align: center;
      font-size: 16px;
      font-weight: 300;
    }
  }
}
