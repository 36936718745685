.bg {
  background-color: hsl(145, 51%, 49%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  .title {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 30px;
  }
}
