.bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 100px 0px;
}

.title {
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 60px;
  .colored {
    color: #3ebd73;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 1000px;

  .featureWrapper {
    display: flex;
    flex-direction: row;

    margin: 0px 0px 40px 0px;
    .featureDetail {
      display: flex;
      flex-direction: column;
      height: 100px;
      width: 300px;

      .featureTitle {
        font-weight: 600;
        font-size: 24px;
      }
      .featureContent {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 300;
      }
    }
  }
}

.icons {
  width: 100px;
  height: 100px;
  object-fit: scale-down;
  margin: 0px 30px;
}
