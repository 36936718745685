.feature-bg {
  background-color: #fff;
  padding: 150px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .feature-title {
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 60px;
  }

  .feature-content {
    font-size: 18px;
    font-weight: 400;
    padding-top: 32px;
  }

  .tabs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.dark {
  background-color: #f7f7f8;
}
