.bg {
  display: flex;
  background-color: #fafafa;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 1200px;
    @media (max-width: 1250px) {
      width: 1000px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 24px;
        font-weight: 700;
        padding-top: 15px;
      }
      .content {
        font-size: 16px;
        color: #000000de;
        padding: 10px 0px 30px 0px;
        font-weight: 300;
      }
    }
  }
}
