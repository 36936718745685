footer {
  background-color: #fff;
  color: #000000de;
  font-weight: 300;
  font-size: 11px;
  display: flex;
  justify-content: center;
  padding: 40px 0px 20px 0px;
  .footer-wrapper {
    width: 1200px;
    display: flex;
    flex-direction: row;
    @media (max-width: 1250px) {
      width: 1000px;
    }
    @media (max-width: 820px) {
      width: 1000px;
      flex-direction: column;
    }
  }
  .footer-logo {
    width: 10%;

    display: flex;
    flex-direction: column;
    .img {
      width: 50px;
      height: auto;
      object-fit: scale-down;
      margin-top: 4px;
    }
    @media (max-width: 820px) {
      width: auto;
    }
  }
  .companyDetails {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (max-width: 820px) {
      width: auto;
    }
  }
  .notice {
    width: 25%;
    display: flex;
    flex-direction: column;
    @media (max-width: 820px) {
      width: auto;
    }
  }
  .copyright {
    width: 40%;
    display: flex;
    flex-direction: column;
    .operator {
      color: #808080;
    }
    @media (max-width: 820px) {
      width: auto;
    }
  }
  .seperator {
    height: 100%;
    width: 1px;
    background-color: lightgray;
    margin-right: 20px;
    @media (max-width: 820px) {
      width: 5px;
      height: 1px;
      margin: 10px 0px;
    }
  }
  .linkSection {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      color: #000000de;
      text-decoration: underline;
    }
    .business {
      display: flex;
      flex-direction: column;
    }
  }
}
