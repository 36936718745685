.bg {
  background-color: #fff;
  text-align: center;
  padding: 100px 0px;

  .title {
    padding: 30px 0px;
    font-size: 30px;
    font-weight: 300;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .wrapper {
      width: 186px;
      height: 150px;
      padding: 40px 20px;

      .img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
  }
}
