.header-landing {
  width: 100%;
  height: 90px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
  @media (max-width: 820px) {
    flex-direction: column;
    height: max-content;
  }
}

.header-clear {
  width: 100%;
  height: 90px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  position: fixed;
  color: white;
  @media (max-width: 820px) {
    flex-direction: column;
    height: max-content;
    color: black;
  }
}

.wrapper {
  width: 1200px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: aqua;
  // border: 1px solid black;

  @media (max-width: 1250px) {
    width: 1000px;
  }

  @media (max-width: 820px) {
    width: 100%;
  }

  .img {
    width: 150px;

    @media (max-width: 820px) {
      width: 100px;
    }
  }
  .tab {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 820px) {
      display: none;
    }
  }

  .hamburger-icon {
    display: none;
    @media (max-width: 820px) {
      display: flex;
    }
  }
}

.menu-open {
  display: none;
  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    background-color: #fffffff2;
  }
}
